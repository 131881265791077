import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { AppBar, Toolbar, IconButton, Typography, Button, Breadcrumbs, Link, Badge } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu'
import { useLocation } from 'react-router-dom'
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  root: {
    backgroundColor: '#F7F9FD',
    flexGrow: 1,
    height: '100vh'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontFamily: 'Barlow',
    fontWeight: 300,
    fontSize: '36px'
  },
  breadcrumbs: {
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(2)
  }
}));

const Main = (props: any) => {
  const { children } = props
  const classes = useStyles();
  const location = useLocation();
  const { accounts, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  console.log(accounts)
  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            size="large">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Circapath.
          </Typography>
          <Badge badgeContent={3} color="error">
            <Button startIcon={<NoteAddIcon />} color="inherit">
              Requests
            </Button>
          </Badge>
          {isAuthenticated &&
            <Button color="inherit" onClick={() => instance.logoutRedirect()}>Logout</Button>
          }
          {!isAuthenticated &&
            <Button color="inherit">Login</Button>
          }

        </Toolbar>
      </AppBar>
      <Breadcrumbs className={classes.breadcrumbs} aria-label="breadcrumb">
        <Link color="inherit" href="/">
          Home
        </Link>
        {
          location.pathname === "/transaction/12323" && <Link color="inherit" href="/getting-started/installation/">
            Transactions
          </Link>
        }
        {
          location.pathname === "/transaction/12323" &&
          <Typography>74 Springbrook Rd</Typography>
        }

      </Breadcrumbs>
      {children}
    </div>
  );
}

export default Main
