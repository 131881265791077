import makeStyles from '@mui/styles/makeStyles';
import { Container, Typography, Grid, Chip, CardContent, Card, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Stack, } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getChecklistByTransactionId, } from '../../api';
import { useState } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useParams } from 'react-router-dom';
import ContactCard from '../../components/cards/ContactCard';
import OfferCard from '../../components/cards/OfferCard';
import OffersSidebar from './OfferSidebar';

const useStyles = makeStyles((theme) => ({
  cardRoot: {
  },
  cardAvatar: {
    backgroundColor: theme.palette.secondary.main
  },
  cardImage: {
    width: '100%'
  },
  tableContainer: {
  },
  tableHeading: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  participantCard: {
    marginBottom: theme.spacing(2)
  },
  informationCard: {
    marginBottom: theme.spacing(2)
  }
}))

type TransactionRouteParams = {
  transactionId: string
}


const Transaction = () => {
  const classes = useStyles()
  const { transactionId } = useParams<TransactionRouteParams>()
  const [items] = useState(getChecklistByTransactionId())
  // const [{ data: transactionResponse, loading: transactionLoading }] = useAxios(`/transactions/${transactionId}`)

  return (
    <Container maxWidth={false}>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <Stack spacing={1}>
            <ContactCard participant={{ firstName: "Brandon", lastName: "Buchanan", phone: "801-555-555", address: "74 Springbrook Rd", email: "brandon@circapath.com" }} />
            <ContactCard participant={{ firstName: "Brandon", lastName: "Buchanan", phone: "801-555-555", address: "74 Springbrook Rd", email: "brandon@circapath.com" }} />
            <ContactCard participant={{ firstName: "Brandon", lastName: "Buchanan", phone: "801-555-555", address: "74 Springbrook Rd", email: "brandon@circapath.com" }} />
          </Stack>
        </Grid>
        <Grid item md={6}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card variant="outlined">
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <img alt="Home" className={classes.cardImage} src="https://photos.zillowstatic.com/fp/3deb06aab195685cdfae86606f780b5b-cc_ft_384.jpg" />
                        </Grid>
                        <Grid item xs={9}>
                          {/* <Typography variant="h3">
                            {transactionResponse && transactionResponse["Property Address"]["Street Number"]} {transactionResponse && transactionResponse["Property Address"]["Street Name"]}
                          </Typography> */}
                          <Typography variant="caption">In Progress</Typography>
                        </Grid>
                      </Grid>
                    </CardContent>

                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <OfferCard offer={{
                    salesPrice: '475000',
                    earnestMoney: '3000',
                    optionFee: '500',
                    optionPeriod: '10',
                    closingDate: new Date('1/1/2023')
                  }} />


                </Grid>
                <Grid item xs={12}>
                  <TableContainer className={classes.tableContainer} component={Paper}>
                    <Typography className={classes.tableHeading} variant="h4">Transaction Progress</Typography>
                    <Table size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell align="right"></TableCell>
                          <TableCell>Task</TableCell>
                          <TableCell>Started</TableCell>
                          <TableCell>Completed</TableCell>
                          <TableCell>Completed By</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {items.map((item, index) => (<TableRow>
                          <TableCell align="right">{item.completed ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}</TableCell>
                          <TableCell>{item.task}</TableCell>
                          <TableCell>{item.startedOn}</TableCell>
                          <TableCell>{item.completedOn}</TableCell>
                          <TableCell>{item.completed && <Chip size="small" icon={<PersonIcon />} label={item.completedBy} />}</TableCell>
                        </TableRow>))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={3}>
          <OffersSidebar transactionId={transactionId} />
        </Grid>
      </Grid>
    </Container >
  )
}

export default Transaction
