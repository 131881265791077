import { Button, Card, CardContent, CardHeader, Skeleton, Stack } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import OfferCard from "../../components/cards/OfferCard";
import useAxios from "axios-hooks";
import OfferForm from "../../components/forms/OfferForm";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';

type OffersSidebarProps = {
  transactionId: string
}
type Offer = {
  salesPrice: string,
  earnestMoney: string,
  optionFee: string,
  optionPeriod: string,
  closingDate: Date,
}

const OffersSidebar = ({ transactionId }: OffersSidebarProps) => {
  const [{ data: offers, loading: offersLoading }, fetchOffers] = useAxios(`/transactions/${transactionId}/offers`)
  const [showOfferForm, setShowOfferForm] = useState(false)
  const offersCallback = () => {
    fetchOffers()
  }

  return (
    <Stack spacing={1}>
      {offersLoading &&
        <>
          <Skeleton variant="rectangular" height={100} />
          <Skeleton variant="rectangular" height={100} />
          <Skeleton variant="rectangular" height={100} />
        </>
      }
      {offers && offers.map((offer: Offer, index: number) => <OfferCard offer={offer} />)}
      <Button color={showOfferForm ? "error" : "primary"} startIcon={showOfferForm ? <CloseIcon /> : <AddIcon />} onClick={() => setShowOfferForm(!showOfferForm)} variant="contained" fullWidth>{showOfferForm ? 'Cancel' : 'Add Offer'}</Button>
      {showOfferForm && (
        <Card variant="outlined">
          <CardHeader title="Create Offer" />
          <CardContent>
            <OfferForm callback={offersCallback} transactionId={transactionId} />
          </CardContent>

        </Card>
      )}


    </Stack>
  )
}

export default OffersSidebar
