export const getChecklistByTransactionId = () => {
  return [
    {
      task: "Contract documents signed and uploaded",
      startedOn: "7/1/2021",
      completedOn: "7/3/2021",
      completedBy: "Jordan Bucahanan",
      completed: true,
    },
    {
      task: "Executed agreement (offer to purchase)",
      startedOn: "7/1/2021",
      completedOn: "7/3/2021",
      completedBy: "Jordan Bucahanan",
      completed: true,
    },
    {
      task: "Agreement between buyer and agent or brokerage",
      startedOn: "7/1/2021",
      completedOn: "7/3/2021",
      completedBy: "Jordan Bucahanan",
      completed: true,
    },
    {
      task: "Buyer's disclosures",
      startedOn: "7/1/2021",
      completedOn: "",
      completedBy: "",
      completed: false,
    },
    {
      task: "Proof of funds or pre-approval",
      startedOn: "",
      completedOn: "",
      completedBy: "",
      completed: false,
    },
    {
      task: "Earnest money",
      startedOn: "",
      completedOn: "",
      completedBy: "",
      completed: false,
    },
    {
      task: "Add inspector",
      startedOn: "",
      completedOn: "",
      completedBy: "",
      completed: false,
    },
    {
      task: "Add lender",
      startedOn: "",
      completedOn: "",
      completedBy: "",
      completed: false,
    },
    {
      task: "Order inspections",
      startedOn: "",
      completedOn: "",
      completedBy: "",
      completed: false,
    },
    {
      task: "Upload inspections",
      startedOn: "",
      completedOn: "",
      completedBy: "",
      completed: false,
    },
  ];
};
