import React from 'react';
import './App.css';
import { Route, useHistory } from 'react-router-dom'
import { Home as HomeView } from './views/Home';
import { Main as Layout } from './components/layouts/Main'
import { Transaction as TransactionView } from './views/Transaction'
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { InteractionType, IPublicClientApplication } from '@azure/msal-browser';
import { CustomNavigationClient } from './utils/NavigationClient';
import { loginRequest } from './authConfig';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';

type AppProps = {
  pca: IPublicClientApplication
}

function App({ pca }: AppProps) {
  const history = useHistory()
  const navigationClient = new CustomNavigationClient(history)
  pca.setNavigationClient(navigationClient)
  const authRequest = {
    ...loginRequest
  };

  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Layout>
            <Route path="/" exact component={HomeView} />
            <Route path="/transaction/:transactionId" component={TransactionView} />
          </Layout>
        </LocalizationProvider>
      </MsalAuthenticationTemplate>
    </MsalProvider >
  );
}

export default App;
