import { Avatar, Card, CardContent, CardHeader, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import PersonIcon from '@mui/icons-material/Person';
import RoomIcon from '@mui/icons-material/Room';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

type Participant = {
  firstName: string,
  lastName: string,
  phone: string,
  address: string,
  email: string,
}

type ContactCardProps = {
  participant: Participant
}

const ContactCard = (props: ContactCardProps) => {

  return (
    <Card variant="outlined">
      <CardHeader avatar={<Avatar sx={{ backgroundColor: 'secondary.main' }}><PersonIcon /></Avatar>} title={`${props.participant.firstName} ${props.participant.lastName}`} subheader="Client" />
      <CardContent sx={{ padding: 0 }}>
        <List dense disablePadding>
          <ListItem>
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText primary={props.participant.phone} />
          </ListItem>
          <ListItemButton href={`mailto:${props.participant.email}`}>
            <ListItemIcon >
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary={props.participant.email} />
          </ListItemButton>
          <ListItem>
            <ListItemIcon>
              <RoomIcon />
            </ListItemIcon>
            <ListItemText primary={props.participant.address} />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  )
}

export default ContactCard;
