import makeStyles from '@mui/styles/makeStyles';
import { Container, Grid, CardHeader, CardContent, Card, CardActions, Button, Avatar, Typography, TextField, ButtonGroup, Paper } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import SyncIcon from '@mui/icons-material/Sync';
import { useHistory } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useState } from 'react';
import useAxios from 'axios-hooks';
import TransactionForm from '../../components/forms/TransactionForm';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    marginBottom: theme.spacing(2),
    borderColor: 'rgba(91, 97, 110, 0.2)',
    borderRadius: 8,
    borderStyle: 'solid'
  },
  cardAvatar: {
    backgroundColor: theme.palette.secondary.main
  },
  loopItImg: {
    height: 40,
    width: 'auto'
  },
  newTransactionBlock: {
    padding: theme.spacing(2)
  },
  searchContainer: {
    display: "flex",
  },
  searchIcon: {
    alignSelf: "flex-end",
    marginBottom: "5px",
  },
  searchInput: {
    margin: "5px",
  },
  filterButton: {
    display: "flex",
    alignSelf: "flex-end",
    marginTop: "15px",
  },
  filterIcon: {
    marginRight: "5px",
    marginLeft: "5px",
  },
  statusFilter: {
    margin: "10px",
  }
}))

type Claims = {
  given_name: string
}

type Transaction = {
  completedTaskCount: number,
  created: Date,
  id: string,
  loopUrl: string,
  name: string,
  status: string,
  totalTaskCount: number,
  transactionType: string,
  updated: string
}
const Home = () => {
  const history = useHistory()
  const classes = useStyles()
  // const [dotloopAuthCode, setDotloopAuthCode] = useState('')
  const [showNewTransactionForm, setShowNewTransactionForm] = useState(false)
  const [{ data: transactions }, refreshTransactions] = useAxios('/transactions')
  // const [{ data: dotloopTokenResponseData }, saveAuthCode] = useAxios({ url: `/api/integrations/dotloop/auth/${dotloopAuthCode}`, method: 'POST' })
  const { accounts } = useMsal()
  const [claims, setClaims] = useState({} as Claims)
  // const [statusFilters, setStatusFilters] = useState([] as string[]);
  // const [filtersList, setFiltersList] = useState(['']);
  const [nameSearch, setNameSearch] = useState('');
  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  const isAuthenticated = useIsAuthenticated()

  const newTransactionCallback = () => {
    setShowNewTransactionForm(false)
    refreshTransactions()
  }
  // if (dotloopTokenResponseData) {
  //   console.log("response data", dotloopTokenResponseData)
  // }

  // useEffect(() => {
  //   const code = new URLSearchParams(location.search).get('code');
  //   if (code) {
  //     setDotloopAuthCode(code)
  //   }
  // }, [location.search])

  // useEffect(() => {
  //   if (dotloopAuthCode) {
  //     saveAuthCode().then(() => {
  //       history.push('/')
  //     })
  //   }
  // }, [dotloopAuthCode, history, saveAuthCode])

  useEffect(() => {
    setClaims(accounts[0].idTokenClaims as Claims)
  }, [isAuthenticated, accounts])

  // useEffect(() => {
  //   var filters: string[] = [];
  //   transactions && transactions.forEach((item: any) => {
  //     if (!filters.includes(item.status)) {
  //       filters.push(item.status);
  //     }
  //   });
  //   setFiltersList(filters);
  // }, [transactions])

  // const getDotLoopAuthToken = () => {
  //   window.open(`https://auth.dotloop.com/oauth/authorize?response_type=code&client_id=df1fe717-133c-40b6-9973-f34898c23fc8&redirect_uri=${process.env.REACT_APP_DOTLOOP_REDIRECT}`)
  // }

  const handleSearchChange = (e: any) => {
    setNameSearch(e.target.value.toLowerCase());
    console.log(nameSearch);
  }

  // const handleFilterClick = (e: any) => {
  //   setAnchorEl(e.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const handleToggle = (filterItem: string) => () => {
  //   const currentIndex = statusFilters.indexOf(filterItem);
  //   const newChecked = [...statusFilters];

  //   if (currentIndex === -1) {
  //     newChecked.push(filterItem);
  //   }
  //   else {
  //     newChecked.splice(currentIndex, 1);
  //   }

  //   setStatusFilters(newChecked);
  // };
  const handleDeleteTransaction = (id: string) => {
    axios.delete(`/transactions/${id}/delete`).then((response) => {
      console.log(response)
    })
  }

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h2">Welcome, {claims.given_name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <ButtonGroup>
            <Button variant={showNewTransactionForm ? 'contained' : 'outlined'} onClick={() => setShowNewTransactionForm(!showNewTransactionForm)} startIcon={showNewTransactionForm ? <CancelIcon /> : <AddIcon />}>
              {showNewTransactionForm ? "Cancel" : "Add Transaction"}
            </Button>
            <Button startIcon={<SyncIcon />}>
              Check Dotloop
            </Button>
          </ButtonGroup>


        </Grid>
        {showNewTransactionForm && (
          <Grid item xs={12}>
            <Paper className={classes.newTransactionBlock}>
              <TransactionForm callback={newTransactionCallback} />
            </Paper>
          </Grid>
        )}

        <Grid item xs={6}>
          <div className={classes.searchContainer}>
            <SearchIcon className={classes.searchIcon} />
            <TextField
              className={classes.searchInput}
              onChange={handleSearchChange}
              fullWidth
              label="Search by name"
              variant="standard"
            />
          </div>
        </Grid>
        {/* <Grid item xs={6}>
          <Button variant="contained" className={classes.filterButton} id="filter-button" aria-controls="filter-options" aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleFilterClick}>
            <FilterListIcon className={classes.filterIcon} />Filter on status {(statusFilters.length !== 0) && statusFilters.join(' | ')}
          </Button>
          <Menu
            id="filter-options"
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {<List className={classes.statusFilter}>
              {
                filtersList && filtersList.map((filterItem: string, index: number) => (
                  <ListItem key={filterItem} dense button onClick={handleToggle(filterItem)}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={statusFilters.indexOf(filterItem) !== -1}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText id={filterItem} primary={filterItem.toString()} />
                  </ListItem>
                ))
              }
            </List>}
          </Menu>
        </Grid> */}
        <Grid item xs={12}>
          {
            transactions && transactions.map((transaction: Transaction, index: number) => (
              transaction.name.toLowerCase().includes(nameSearch) &&
              <Card className={classes.cardRoot}>
                <CardHeader avatar={<Avatar className={classes.cardAvatar}><HomeIcon /></Avatar>} title={transaction.name} subheader={transaction.transactionType} />
                <CardContent>
                  Status: {transaction.status}
                </CardContent>
                <CardActions>
                  <ButtonGroup variant="contained">
                    <Button onClick={() => history.push(`/transaction/${transaction.id}`)}>View Details</Button>
                    <Button onClick={() => handleDeleteTransaction(transaction.id)} startIcon={<DeleteIcon />}>Delete</Button>
                  </ButtonGroup>
                </CardActions>
              </Card>
            ))
          }
        </Grid>
      </Grid>
    </Container>
  )
}

export default Home
