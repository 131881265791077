import { DatePicker } from '@mui/lab'
import { Button, InputAdornment, Stack, TextField } from '@mui/material'
import useAxios from 'axios-hooks'
import { useForm, Controller } from 'react-hook-form'
type OfferFormData = {
  salesPrice: string,
  earnestMoney: string,
  optionFee: string,
  optionPeriod: string,
  closingDate: Date,
}

type OfferFormProps = { callback: () => void, transactionId: string }

const OfferForm = ({ callback, transactionId }: OfferFormProps) => {
  const { handleSubmit, control } = useForm()

  const [{ data }, callCreateOffer] = useAxios({ url: `/transactions/${transactionId}/offers/create`, method: 'POST' }, { manual: true })
  const handleSubmitOffer = (formData: OfferFormData) => {
    callCreateOffer({ data: formData }).then((response) => {
      if (response.status === 200) {
        callback()
        console.log(formData, response, data)
      }
    })
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitOffer)}>
      <Stack spacing={2}>
        <Controller
          rules={{ required: true }}
          render={({ field }) => <TextField InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }} type="decimal" {...field} label="Sales Price" fullWidth />}
          name="salesPrice"
          control={control}
          defaultValue=""
        />
        <Controller
          rules={{ required: true }}
          render={({ field }) => <TextField InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}{...field} label="Earnest Money" fullWidth />}
          name="earnestMoney"
          control={control}
          defaultValue=""
        />
        <Controller
          rules={{ required: true }}
          render={({ field }) => <TextField InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }} {...field} label="Option Fee" fullWidth />}
          name="optionFee"
          control={control}
          defaultValue=""
        />
        <Controller
          rules={{ required: true }}
          render={({ field }) => <TextField InputProps={{ endAdornment: <InputAdornment position="end">days</InputAdornment>, defaultValue: 10 }} type="number" {...field} label="Option Period" fullWidth />}
          name="optionPeriod"
          control={control}
          defaultValue={10}
        />
        <Controller
          rules={{ required: true }}
          render={({ field }) => <DatePicker
            label="Closing Date"
            renderInput={(params) => <TextField {...params} />}
            {...field}
          />}
          name="closingDate"
          control={control}
          defaultValue=""
        />

        <Button variant="contained" type="submit">Submit</Button>
      </Stack>
    </form>
  )
}

export default OfferForm
