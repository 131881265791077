import { Avatar, Typography, Card, CardContent, CardHeader, List, ListItem, ListItemButton, ListItemText } from "@mui/material"
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import NumberFormat from "react-number-format";
import moment from 'moment'
import { useState } from "react";
type Offer = {
  salesPrice: string,
  earnestMoney: string,
  optionFee: string,
  optionPeriod: string,
  closingDate: Date,
}

type OfferCardProps = {
  offer: Offer
}

const OfferCard = ({ offer }: OfferCardProps) => {
  const [collapsed, setCollapsed] = useState(true)
  console.log(moment(offer.closingDate, 'YYYY-MM-DD[T]HH:mm:ss').format('MM/DD/YYYY'), offer.closingDate)
  return (
    <Card variant="outlined">
      <CardHeader onClick={() => setCollapsed(!collapsed)} avatar={<Avatar sx={{ backgroundColor: 'secondary.main' }}><MonetizationOnIcon /></Avatar>} title={
        <Typography variant="h5"><NumberFormat
          thousandsGroupStyle="thousand"
          value={offer.salesPrice}
          prefix="$"
          decimalSeparator="."
          displayType="text"
          type="text"
          thousandSeparator={true} /></Typography>} subheader="Offer" />
      {!collapsed && <CardContent>
        <List dense disablePadding>
          <ListItem>
            <ListItemText primary={<NumberFormat
              thousandsGroupStyle="thousand"
              value={offer.earnestMoney}
              prefix="$"
              decimalSeparator="."
              displayType="text"
              type="text"
              thousandSeparator={true} />} secondary="Earnest Money" />
          </ListItem>
          <ListItemButton>
            <ListItemText primary={<NumberFormat
              thousandsGroupStyle="thousand"
              value={offer.optionFee}
              prefix="$"
              decimalSeparator="."
              displayType="text"
              type="text"
              thousandSeparator={true} />} secondary="Earnest Money" />
          </ListItemButton>
          <ListItem>
            <ListItemText primary={offer.optionPeriod} secondary="Option Period" />
          </ListItem>
          <ListItem>
            <ListItemText primary={moment(offer.closingDate, 'YYYY-MM-DD[T]HH:mm:ss').format('MM/DD/YYYY')} secondary="Closing Date" />
          </ListItem>
        </List>

      </CardContent>}

    </Card>
  )
}

export default OfferCard;
