import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: "b17f1ff2-7bb4-4c47-972d-dc96da353b41",
    authority: 'https://roadhometest.b2clogin.com/roadhometest.onmicrosoft.com/B2C_1_signin',
    knownAuthorities: ['roadhometest.b2clogin.com']
  }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: []
  // scopes: ["User.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
};
