import { Button, Stack, TextField, Typography } from '@mui/material'
import useAxios from 'axios-hooks'
import { useForm, Controller } from 'react-hook-form'
type TransactionFormData = {
  name: string
}

type TransactionFormProps = { callback: () => void }

const TransactionForm = ({ callback }: TransactionFormProps) => {
  const { handleSubmit, control } = useForm()
  const [{ data }, callCreateTransaction] = useAxios({ url: `/transactions/create`, method: 'POST' }, { manual: true })
  const handleSubmitTransaction = (formData: TransactionFormData) => {
    callCreateTransaction({ data: { name: formData.name, status: "Jorge" } }).then((response) => {
      if (response.status === 200) {
        callback()
        console.log(formData, response, data)
      }
    })
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitTransaction)}>
      <Stack spacing={2}>
        <Typography variant="h4">Create Transaction</Typography>
        <Controller
          rules={{ required: true }}
          render={({ field }) => <TextField {...field} label="Name" fullWidth />}
          name="name"
          control={control}
          defaultValue=""
        />
        <Button variant="contained" type="submit">Submit</Button>
      </Stack>
    </form>
  )
}

export default TransactionForm
