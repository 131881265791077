import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import { roadHomeTheme } from './styles/theme';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult, SilentRequest } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import axios from 'axios';

export const msalInstance = new PublicClientApplication(msalConfig);

const accessTokenRequest: SilentRequest = {
  scopes: [process.env.REACT_APP_CP_B2C_ACCESS_TOKEN_SCOPE || ''],
  account: msalInstance.getAllAccounts()[0]
}

axios.interceptors.request.use(
  async (config) => {
    const token = await (await msalInstance.acquireTokenSilent(accessTokenRequest)).accessToken

    if (token) {
      config.headers = {
        authorization: `Bearer ${token}`
      };
    }

    config.baseURL = process.env.REACT_APP_CP_BACKYARD_API_BASE_URL
    return config;
  },
  (error) => Promise.reject(error)
);


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={roadHomeTheme}>
          <BrowserRouter>
            <App pca={msalInstance} />
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
