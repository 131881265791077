import { createTheme } from "@mui/material/styles";
export const roadHomeTheme = createTheme({
  palette: {
    primary: {
      main: '#003F63',
      // secondary: '#F2B138',
      // dark: '#353D40',
      // grey: '#A1A5A6',
      // lightGrey: '#D9D9D9'
    },
    secondary: {
      light: '#F2B138',
      main: '#F2B138'
    }
  },
  typography: {
    fontFamily: ['"Barlow"', 'Open Sans'].join(',')
  },

})
